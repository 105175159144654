<template>
    <div>
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? CoverNote__title : ''"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <CoverNoteAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="addMode && newCoverNoteUnsaved"
                ></CoverNoteAlert>
                <CoverNoteAlert
                    :alert="$t('message.closeAlert')"
                    mode="add"
                    type="warning"
                    class="mt-3"
                    v-if="updateMode && updatedCoverNoteUnsaved"
                ></CoverNoteAlert>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-overlay
                        :value="loader"
                        absolute
                        opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                    color="primary"
                                    indeterminate
                                    size="40"
                                    width="6"
                                ></v-progress-circular>
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-expansion-panels
                        accordion
                        v-model="panel"
                        multiple
                    >
                        <v-expansion-panel>
                            <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                            <v-expansion-panel-content class="dense-inputs">
                                <v-layout row pt-6 v-if="updateMode == true">
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center primary lighten-4">{{ $t('message.loadActiveCoverNote') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <v-autocomplete
                                            :items="allActiveCoverNotes"
                                            :loading="loadingAnotherCoverNote"
                                            dense
                                            hide-details
                                            item-value="CoverNote.id"
                                            item-text="CoverNote.title"
                                            solo
                                            @change="loadAnotherActiveCoverNote($event)"
                                        >
                                            <template v-slot:item="{item}">
                                                <div class="d-flex flex-column py-1">
                                                    <span class="font-weight-bold">{{ item.CoverNote.title }}</span>
                                                    <span class="font-xs">{{ $t('message.contract') + ': ' + (item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title) }}</span>
                                                    <span class="font-xs">{{ $t('message.buyer') + ': ' + item.Customer.title }}</span>
                                                    <span class="font-xs">{{ $t('message.supplier') + ': ' + (item.Supplier.code ? item.Supplier.code : item.Supplier.otsname) }} </span>
                                                </div>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                </v-layout>
                                <v-layout row pt-3 v-if="addMode == true">
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="red--text align-center">{{ $t('message.relatedContract') }}*</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <v-autocomplete
                                            :items="allActiveContracts"
                                            :placeholder="$t('message.startTypingToSearch')"
                                            :value="CoverNote__contract_id"
                                            dense
                                            hide-details
                                            item-value="Contract.id"
                                            item-text="Contract.title"
                                            required
                                            return-object
                                            solo
                                            @change="loadContract($event)"
                                        >
                                            <template v-slot:item="{item}">
                                                <div class="d-flex flex-column py-1">
                                                    <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                                    <span class="font-sm"> {{ $t('message.buyer') + ': ' + item.Customer.title }} </span>
                                                    <span class="font-sm"> {{ $t('message.supplier') + ': ' + item.Supplier.title }} </span>
                                                </div>
                                            </template>
                                            <template v-slot:selection="{item}">
                                                <span>{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                                            </template>
                                        </v-autocomplete>
                                    </v-flex>
                                </v-layout>
                                <v-layout row v-if="updateMode == true">
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.contract') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>{{ CoverNote__Contract__version != null ? [CoverNote__Contract__title, CoverNote__Contract__revision_no , CoverNote__Contract__partial_no].filter(Boolean).join('-') : CoverNote__Contract__title }}</v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.coverNoteNumber') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <v-text-field
                                            :value="CoverNote__title"
                                            autocomplete="new-password"
                                            clearable
                                            dense
                                            hide-details
                                            solo
                                            @change="CoverNote__title = $event"
                                        ></v-text-field>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-right="40"
                                            max-width="290px"
                                            min-width="290px"
                                            ref="invoiceDateMenu"
                                            transition="scale-transition"
                                            v-model="coverNoteDateMenu"
                                        >
                                            <template v-slot:activator="{ on }">
                                                <v-text-field
                                                    :label="CoverNote__ttadate ? '' : $t('message.required')"
                                                    :value="computedCoverNoteDateFormatted"
                                                    append-icon="event"
                                                    clearable
                                                    class="force-text-left"
                                                    hint="DD/MM/YYYY format"
                                                    hide-details
                                                    background-color="white"
                                                    persistent-hint
                                                    readonly
                                                    solo
                                                    v-on="on"
                                                ></v-text-field>
                                            </template>
                                            <v-date-picker
                                                first-day-of-week="1"
                                                locale-first-day-of-year="4"
                                                show-week
                                                v-model="CoverNote__ttadate"
                                                @input="coverNoteDateMenu = false"
                                            ></v-date-picker>
                                        </v-menu>
                                    </v-flex>
                                </v-layout>
                                <v-layout row>
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.remarks') }}</v-flex>
                                    <v-flex xs6 lg3 md5 py-1>
                                        <v-textarea
                                            :value="CoverNote__remark"
                                            clearable
                                            dense
                                            hide-details
                                            rows="3"
                                            solo
                                            @change="CoverNote__remark = $event"
                                        ></v-textarea>
                                    </v-flex>
                                </v-layout>
                                <v-layout v-if="CoverNote__Specifications.length > 0">
                                    <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.specifications') }}</v-flex>
                                </v-layout>
                                <v-layout v-if="CoverNote__Specifications.length > 0">
                                    <v-flex xs12 lg12 md12>
                                        <v-simple-table dense>
                                            <template v-slot:default>
                                                <thead>
                                                    <tr>
                                                        <th class="px-3 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.description') }}</th>
                                                        <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.size') }}</th>
                                                        <th class="px-0 subtitle-1 font-weight-black grey lighten-4">{{ $t('message.grade') }}</th>
                                                        <th class="px-0 subtitle-1 font-weight-black grey lighten-4 text-right">{{ $t('message.quantity') }}</th>
                                                        <th class="px-0 subtitle-1 font-weight-black grey lighten-4 text-right">{{ $t('message.unitPrice') }}</th>
                                                        <th class="px-3 subtitle-1 font-weight-black grey lighten-4 text-right">{{ $t('message.amount') }}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-for="item in CoverNote__Specifications" v-bind:key="item.index" v-if="item.type == 'default'">
                                                        <td class="px-3 border-0">{{ item.Salesdescription.title }}</td>
                                                        <td class="px-0 border-0">{{ item.Size.title }}</td>
                                                        <td class="px-0 border-0">{{ item.Grade.title }}</td>
                                                        <td class="px-0 text-right border-0">{{ item.type != 'credit' ? formatThisNumber((CoverNote__Contract__salestype_id == 2 ? item.Salesitem.itemqty : item.Salesitem.invqty), uofmPrecision(item.Measurement.title)) + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.Salesitem.measurement_id).Measurement.title : '' }}</td>
                                                        <td class="px-0 text-right border-0">{{ item.type != 'credit' ? formatThisNumber((CoverNote__Contract__salestype_id == 2 ? item.Salesitem.unitprice : item.Salesitem.invprice),currencyFormatPrecision) : '' }}</td>
                                                        <td class="px-3 text-right border-0">{{ item.type != 'credit' ? formatThisNumber((CoverNote__Contract__salestype_id == 2 ? item.Salesitem.amount : item.Salesitem.invoiceamount),currencyFormatPrecision) : '(' + formatThisNumber(item.Salesitem.invoiceamount,currencyFormatPrecision) + ')' }}</td>
                                                    </tr>
                                                    <tr v-else-if="item.type == 'othercharge'">
                                                        <td></td>
                                                        <td class="px-0">{{ item.Salesitem.size }}</td>
                                                        <td></td>
                                                        <td></td>
                                                        <td class="px-0 text-right border-0">{{ formatThisNumber(item.Salesitem.amount,currencyFormatPrecision) }}</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="border-top-2"></td>
                                                        <td class="border-top-2"></td>
                                                        <td class="px-0 font-weight-bold border-top-2">
                                                            <span class="pr-2">{{ $t('message.total') }}</span>
                                                            <span v-if=" [1,2,3,5].includes(CoverNote__Contract__containersize_id) ">{{ CoverNote__Contract__containercount }}</span>
                                                            <span v-if=" [1,2,3,5].includes(CoverNote__Contract__containersize_id) "> X </span>
                                                            <span>{{ containerSizes.find(containerSize => containerSize.Containersize.id == CoverNote__Contract__containersize_id).Containersize.title }}</span>
                                                        </td>
                                                        <td class="px-0 text-right font-weight-bold border-top-2">
                                                            <span v-for="(total,index) in CoverNote__itemsTotalQty">
                                                                {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                                            </span>
                                                        </td>
                                                        <td class="px-0 text-right font-weight-bold border-top-2">{{ CoverNote__Contract__currency_id != null ? currencies.find((currency) => currency.Currency.id == CoverNote__Contract__currency_id).Currency.code : '' }}</td>
                                                        <td class="pl-0 pr-3 text-right font-weight-bold border-top-2">{{ formatThisNumber(CoverNote__itemsTotalAmount,currencyFormatPrecision) }}</td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-flex>
                                </v-layout>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                        <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_covernote')">
                            <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <DocumentHistory asset="covernotes" :asset_id="CoverNote__id"></DocumentHistory>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                    <v-layout row>
                        <v-flex d-flex xs12>
                            <v-layout row px-3 pt-3 mb-3>
                                <v-btn
                                    class="ml-3"
                                    small
                                    v-if="updateMode == true"
                                    @click.stop="viewPdf()"
                                >
                                    <v-icon small>far fa-file-pdf</v-icon>
                                    <span class="ml-1">{{ $t('message.pdf') }}</span>
                                </v-btn>
                                <v-btn
                                    :loading="loading.reset"
                                    @click="resetCoverNoteForm()"
                                    class="ml-3"
                                    color="default"
                                    small
                                    v-if="addMode == true"
                                >
                                    <v-icon>mdi-repeat</v-icon> {{ $t('message.reset') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.add"
                                    class="ml-3"
                                    color="info"
                                    small
                                    @click="addCoverNote()"
                                    v-if="addMode == true"
                                >{{ $t('message.saveNewCoverNote') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.save"
                                    class="ml-3"
                                    color="info"
                                    small
                                    @click="saveCoverNote()"
                                    v-if="addMode == false"
                                >
                                    {{ $t('message.saveChanges') }}
                                </v-btn>
                                <v-btn
                                    :loading="loading.cancel"
                                    class="ml-3"
                                    color="error"
                                    small
                                    v-if="addMode == false"
                                    @click="cancelCoverNote(CoverNote__id)"
                                >{{ $t('message.cancelCoverNote') }}
                                </v-btn>
                            </v-layout>
                        </v-flex>
                    </v-layout>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import { mapFields, mapMultiRowFields } from "vuex-map-fields";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {formatDate, numberFormat} from "Helpers/helpers";
import router from "../../../router";
// import DocumentHistory from "../../../components/Appic/DocumentHistory";
// import CoverNoteAlert from "Components/Appic/CoverNoteAlert";
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import { v4 as uuidv4 } from 'uuid';

const DocumentHistory = () => import("../../../components/Appic/DocumentHistory");
const CoverNoteAlert = () => import("Components/Appic/CoverNoteAlert");

export default {
    name: "AddCoverNote",
    components: {CoverNoteAlert, DocumentHistory },
    props: ['coverNoteId'],
    title: '',
    data() {
        return {
            coverNoteDateMenu: false,
            coverNoteDateFormatted: formatDate(new Date().toISOString().substr(0,10)),
            dialogs: {
                error:  false,
                error_message: ""
            },
            loader: false,
            loading: {
                add: false,
                reset: false,
                save: false
            },
            loadingAnotherCoverNote: false,
            panel: [0]
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('covernote',{
            CoverNote__id: 'CoverNote.id',
            CoverNote__contract_id: 'CoverNote.contract_id',
            CoverNote__itemsTotalAmount: 'CoverNote.itemsTotalAmount',
            CoverNote__itemsTotalQty: 'CoverNote.itemsTotalQty',
            CoverNote__remark: 'CoverNote.remark',
            CoverNote__title: 'CoverNote.title',
            CoverNote__ttadate: 'CoverNote.ttadate',
            CoverNote__Contract__currency_id: 'CoverNote.Contract.currency_id',
            CoverNote__Contract__containercount: 'CoverNote.Contract.containercount',
            CoverNote__Contract__containersize_id: 'CoverNote.Contract.containersize_id',
            CoverNote__Contract__partial_no: 'CoverNote.Contract.partial_no',
            CoverNote__Contract__revision_no: 'CoverNote.Contract.revision_no',
            CoverNote__Contract__salestype_id: 'CoverNote.Contract.salestype_id',
            CoverNote__Contract__version: 'CoverNote.Contract.version',
            CoverNote__Contract__title: 'CoverNote.Contract.title',
            CoverNote__Specifications: 'CoverNote.Specifications'
        },'statePrefix'),
        ...mapFields('covernote',{
            newCoverNoteSaved: 'newCoverNoteSaved',
            newCoverNoteUnsaved: 'newCoverNoteUnsaved',
            updatedCoverNoteUnsaved: 'updatedCoverNoteUnsaved',
            updatedCoverNoteSaved: 'updatedCoverNoteSaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters([
            'containerSizes',
            'currencies',
            'uofmQty'
        ]),
        ...mapGetters('contract',{
            allContractsWithoutCoverNote: 'allContractsWithoutCoverNote',
            allActiveContracts: 'allActiveContracts'
        }),
        ...mapGetters('covernote',{
            allActiveCoverNotes: 'allActiveCoverNotes'
        }),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_covernote')
        },
        computedCoverNoteDateFormatted () {
            return formatDate(this.CoverNote__ttadate)
        },
        currencyFormatPrecision () {
            return this.currencies.find((currency) => currency.Currency.id === this.CoverNote__Contract__currency_id)?.Currency?.formatPrecision
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_covernote')
        },
    },
    methods: {
        ...mapActions('covernote',{
            cancelCoverNoteById: 'cancelCoverNoteById',
            createCoverNote: 'createCoverNote',
            getAllActiveCoverNotes: 'getAllActiveCoverNotes',
            getContractSpecifications: 'getContractSpecifications',
            getCoverNoteToUpdateById: 'getCoverNoteToUpdateById',
            loadContractWithoutCoverNote: 'loadContractWithoutCoverNote',
            resetAllActiveCoverNotes: 'resetAllActiveCoverNotes',
            resetCurrentCoverNote: 'resetCurrentCoverNote',
            updateCoverNote: 'updateCoverNote'
        }),
        ...mapActions('contract',{
            getAllActiveContracts: 'getAllActiveContracts'
        }),
        addCoverNote () {
            this.loading.add = true
            this.createCoverNote()
                .then((response)=>{
                    if(response.status == 'success'){
                        this.$toast.success(this.$t('message.successes.coverNoteAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        this.loading.add = false
                        this.newCoverNoteUnsaved = false
                        this.newCoverNoteSaved = true
                        this.resetCurrentCoverNote().then(()=>{
                            this.resetAllActiveCoverNotes().then(()=>{
                                router.push({name: 'update_covernote', params: { coverNoteId : response.tta_id}}).catch(err => {})
                            })
                        })
                    } else {
                        this.$toast.error(this.$t('message.errors.coverNoteNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.loading.add = false
                    }
                })
                .catch(()=>{
                    this.$toast.error(this.$t('message.errors.coverNoteNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.add = false
                })
                .finally(()=>{
                    this.loading.add = false
                })
        },
        async cancelCoverNote (val) {
            if(await this.$root.$confirm(this.$t('message.cancelCoverNote'), this.$t('message.confirmations.continueCoverNoteCancelAction'), {color: 'orange'})){
                this.cancelCoverNoteById(val).then((status) => {
                    if(status == 'done'){
                        this.$toast.success(this.$t('message.successes.coverNoteDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                        router.push({name: 'shipping_documents_listing'}).catch(err => {})
                        this.loading.cancel = false
                    } else {
                        this.$toast.error(this.$t('message.errors.coverNoteNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    }
                })
                .catch(()=>{
                    this.$toast.error(this.$t('message.errors.lcaNotDeleted'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                })
                .finally(()=>{
                    this.loading.cancel = false
                })
            }
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        loadAnotherActiveCoverNote (val) {
            router.push({name: 'update_covernote', params: { coverNoteId : val}}).catch(err => {})
        },
        loadContract (contract) {
            this.loader = true
            let self = this
            setTimeout(function(){
                self.loadContractWithoutCoverNote(contract)
                    .then(() => {
                        let payload = {
                            contract_id: self.CoverNote__contract_id,
                            prefix: self.statePrefix
                        }
                        self.getContractSpecifications(payload)
                            .catch(()=>{
                                self.loader = false
                            })
                            .then(()=>{
                                self.loader = false
                            })
                    })
            },100)
        },
        loadCoverNoteById (val) {
            this.loader = true
            this.getCoverNoteToUpdateById(val)
                .then(() => {
                    let payload = {
                        contract_id: this.CoverNote__contract_id,
                        prefix: this.statePrefix
                    }
                    this.getContractSpecifications(payload)
                        .then(()=>{
                            this.loader = false
                            this.updatedCoverNoteUnsaved = false
                        })
                        .catch(()=>{
                            this.loader = false
                            this.updatedCoverNoteUnsaved = false
                        })
                        .then(()=>{
                            this.loader = false
                            this.updatedCoverNoteUnsaved = false
                        })
                })
                .catch(()=>{
                    this.loader = false
                    this.updatedCoverNoteUnsaved = false
                })
                .then(()=>{
                    this.loader = false
                    this.updatedCoverNoteUnsaved = false
                })
        },
        async resetCoverNoteForm() {
            if (await this.$root.$confirm(this.$t('message.resetCoverNoteForm'), this.$t('message.confirmations.continueCoverNoteFormReset'), {color: 'orange'})) {
                this.loading.reset = true
                this.resetCurrentCoverNote()
                    .then(() => {
                        this.newCoverNoteSaved = false
                        this.newCoverNoteUnsaved = false
                        this.loading.reset = false
                        this.$toast.success(this.$t('message.coverNoteFormResetNotification'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'check_circle_outline'
                            }
                        )
                    })
                    .catch(()=>{
                        this.loading.reset = false
                    })
                    .then(()=>{
                        this.loading.reset = false
                    })
            }
        },
        saveCoverNote () {
            this.loading.save = true
            this.updateCoverNote().then((status) => {
                if(status == 'done'){
                    this.$toast.success(this.$t('message.successes.coverNoteUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'check_circle_outline'
                        }
                    )
                    this.loading.save = false
                    this.updatedCoverNoteUnsaved = false
                } else {
                    this.$toast.error(this.$t('message.errors.coverNoteNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    this.loading.save = false
                }
            })
            .catch(()=>{
                this.$toast.error(this.$t('message.errors.coverNoteNotUpdated'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
                this.loading.save = false
            })
            .finally(()=>{
                this.loading.save = false
            })
        },
        uofmPrecision (uofm) {
            return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
        },
        viewPdf () {
            let document = 'CN ' + this.CoverNote__title
            let user = JSON.parse(localStorage.getItem('user'))
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + '/v1/covernotes/print/'
                + this.coverNoteId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=10mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    created () {
        if(this.allContractsWithoutCoverNote.length == 0) this.getAllActiveContracts()
        if(this.allActiveCoverNotes.length == 0) this.getAllActiveCoverNotes()

        if(this.$route.matched.some(({name}) => name === 'update_covernote')) {
            this.loadCoverNoteById(this.coverNoteId)
        } else if(this.$route.matched.some(({name}) => name === 'add_covernote')){
            this.newCoverNoteUnsaved = true;
        }
    },
    mounted() {
        this.$store.watch(
            function(state) {
                return state.appic.covernote.update.CoverNote
            },
            () => {
                this.updatedCoverNoteUnsaved = true
            },
            { deep: true }
        )
        this.$store.watch(
            function(state) {
                return state.appic.covernote.current.CoverNote
            },
            () => {
                this.newCoverNoteUnsaved = true
            },
            { deep: true }
        )
        this.$title = this.$t('message.coverNote')
    }
}
</script>

<style>
    .font-xs {
        font-size: small;
    }
</style>